@charset "UTF-8";
/** GENERAL **/
html,
body {
  font-weight: 500; }

p {
  margin-bottom: 0; }

/* The container */
.container-radioButton {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default radio button */
.container-radioButton input {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%; }

/* On mouse-over, add a grey background color */
.container-radioButton:hover input ~ .checkmark {
  background-color: #ccc; }

/* When the radio button is checked, add a blue background */
/* .container-radioButton input:checked ~ .checkmark, */
.container-radioButton input ~ .checkmark.checked {
  background-color: #2196f3; }

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the indicator (dot/circle) when checked */
/* .container-radioButton input:checked ~ .checkmark:after, */
.container-radioButton input ~ .checkmark.checked:after {
  display: block; }

/* Style the indicator (dot/circle) */
.container-radioButton .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white; }

/** End **/
.icon-readiness:hover {
  color: blue; }

/** Start button preview **/
.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 21px; }
  .switch input {
    display: none; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e8ebf1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px; }

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 20px;
  left: 4px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%; }

input:checked + .slider {
  background-color: #34bfa3; }

input:focus + .slider {
  box-shadow: 0 0 1px #34bfa3; }

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(20px); }

/*------ ADDED CSS ---------*/
.slider:after {
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 2px;
  font-size: 10px;
  font-family: Verdana, sans-serif; }

/** End **/
.block-people .del-value-people-btn {
  visibility: hidden; }

.block-people:hover .del-value-people-btn {
  visibility: visible; }

.list-member-role-group .del-employee-btn {
  visibility: hidden; }

.list-member-role-group:hover .del-employee-btn {
  visibility: visible; }

/** Start block recent Activities**/
.notify-request {
  position: relative; }

.alert-request {
  position: absolute; }

.notify-request .alert-request-off {
  visibility: hidden; }

.notify-request:hover .alert-request-off {
  visibility: visible; }

.notify-request:hover .alert-request {
  visibility: hidden; }

/** End**/
/** Start request-mode-label **/
.request-mode-label {
  background-color: #ed6b75;
  color: #fff;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  margin: 21px 0px 0px 0px;
  padding: 0px 15px 0px 0px;
  left: -25px; }

.request-mode-label::before {
  display: block;
  z-index: 1;
  content: "";
  height: 30px;
  position: absolute;
  top: 0;
  left: -16px;
  width: 17px;
  background: #ed6b75; }

.request-mode-label::after {
  display: block;
  background-color: transparent;
  content: "";
  height: 30px;
  position: absolute;
  top: 15px;
  left: -21px;
  width: 20px;
  border-top: 15px solid transparent;
  border-right: 15px solid #000;
  border-bottom: 15px solid transparent; }

@media only screen and (max-width: 576px) {
  .request-mode-label {
    left: 0px; } }

/** End**/
/** Start block recent Activities**/
.block-activity {
  display: flex;
  border-radius: 20px;
  padding: 5px 0px;
  margin-bottom: 10px; }

.block-activity:hover {
  background: #eee; }

.block-activity .del-activity-btn {
  visibility: hidden; }

.block-activity:hover .del-activity-btn {
  visibility: visible; }

.block-activity .del-activity-btn:hover {
  cursor: pointer; }

/** End **/
/** Start Order List**/
.block-order-list .btn-remove-order {
  visibility: hidden; }

.block-order-list:hover .btn-remove-order {
  visibility: visible; }

/** End **/
/** dashboard CSS **/
.modal-file-type {
  /* position: absolute;
  float: left;
  left: 50%;
  transform: translate(-50%, -50%); */
  top: 50%; }

#table-scroll-booster .tbl {
  display: flex;
  border-radius: 5px;
  overflow: hidden; }

#table-scroll-booster .table {
  margin: 0; }

#table-scroll-booster .tbl-main-wrap {
  flex: 1;
  position: relative;
  min-width: 0; }

#table-scroll-booster .tbl-main {
  width: 100%;
  overflow: hidden;
  cursor: grab; }

#table-scroll-booster .tbl-main td {
  white-space: nowrap;
  background: #f4f4f4;
  min-width: 140px; }

#table-scroll-booster .tbl-main th {
  text-align: left; }

#table-scroll-booster .table td {
  padding: 8px;
  background: #fff;
  height: 55px; }

#table-scroll-booster .thead th {
  padding: 15px 8px; }

/** dashboard CSS **/
/** GENERAL CSS **/
.step-number-header {
  display: inline-block;
  padding: 0.65em 1em;
  margin-right: 5px;
  background-color: #ed7d32;
  color: white;
  text-align: center;
  border-radius: 50px !important; }

.tips-step {
  display: inline-block;
  padding: 0.65em 1.2em;
  margin-right: 5px;
  background-color: #ed7d32;
  color: white;
  text-align: center;
  border-radius: 50px !important; }

.icon-reload-preview {
  font-weight: bold;
  font-size: 20px;
  color: blue;
  margin-left: 15px;
  vertical-align: bottom;
  cursor: pointer; }

.iframe-letter {
  height: 0;
  width: 0;
  position: absolute;
  opacity: 0; }

#request-letters .letter-block {
  margin-left: 0px;
  margin-bottom: 1em; }

#request-letters .letter-block.child-letter {
  margin-left: 2em; }

#request-letters .attachment-letter {
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #000;
  font-weight: normal;
  font-size: small;
  border-radius: 20px;
  box-sizing: border-box;
  margin-right: 5px;
  padding: 0 10px;
  height: 30px; }
  #request-letters .attachment-letter:hover {
    cursor: pointer; }
  #request-letters .attachment-letter.active {
    border: 1px solid red; }

#request-letters .attachment-letter i {
  font-size: 20px;
  padding-right: 2px;
  color: red; }

#request-letters .letter-block .letter-body {
  border-left: 5px solid;
  border-left-color: inherit; }

#request-letters .letter-block .letter-body,
#request-letters .letter-block .letter-body:hover,
#request-letters .letter-block .letter-body .letter-content-block,
#request-letters .letter-block .letter-body .letter-content-block:hover,
#request-letters .letter-block .letter-body .letter-content,
#request-letters .letter-block .letter-body .letter-content:hover {
  background: #fff !important; }

#request-letters .letter-block .letter-body .letter-content-block .letter-content {
  display: block;
  float: left;
  margin: 10px 0; }

#request-letters .letter-block.style-void,
#request-letters .letter-block.style-void .letter-header,
#request-letters .letter-block.style-void .letter-body {
  border-color: #a5a5a5 !important;
  background: #a5a5a5 !important; }

#request-letters .letter-block.style-void:hover .letter-header {
  cursor: default; }

#request-letters .letter-block.style-void .letter-header .title {
  text-decoration: line-through; }

#request-letters .letter-block .title,
#request-letters .letter-block .created-datetime {
  color: #333;
  margin: 0; }

.tag-labels-file {
  background: #4caf50;
  color: white;
  height: auto;
  width: auto;
  padding: 0.15rem 0.75rem;
  border-radius: 2px; }

.modal-more-option {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate3d(-149px, -276px, 0px); }

span.dropdown-item.first-item {
  background-color: white !important;
  border-bottom: 1px solid #ebedf2;
  font-weight: 600; }

.text-decoration {
  text-decoration: line-through; }

#block-icon-letter .del-letter-request {
  visibility: hidden; }

#block-icon-letter:hover .del-letter-request {
  visibility: visible; }

.dashboard-view-active {
  color: white !important;
  background: cornflowerblue;
  padding-right: 5px;
  font-weight: 400 !important;
  border-radius: 5px; }

.d-flex-center {
  display: flex;
  align-items: center; }

.checkbox-permission label {
  padding-left: 5px;
  vertical-align: middle; }

.technical-icon {
  width: 80px; }

.block-emailTo {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ebedf2;
  padding: 5px;
  border-radius: 0.25rem;
  background-color: white; }

.email-entered {
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #f29900;
  border-radius: 10px;
  box-sizing: border-box;
  height: 20px;
  line-height: 20px;
  margin: 2px 0.33333333em 2px 0;
  padding-left: 8px;
  padding-right: 4px; }

.email-entered.invalid {
  background-color: #d93025;
  border: none;
  color: #fff; }

.email-remove-btn {
  height: 16px;
  opacity: 0.54;
  width: 16px;
  background-image: url(/assets/img/close_black.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  margin-left: 4px; }

.email-remove-btn:hover {
  cursor: pointer; }

.email-input {
  display: inline-flex;
  outline: none;
  border: none;
  color: #575962;
  padding-left: 8px;
  font-size: 1rem;
  font-family: sans-serif, Arial; }

.block-subject {
  margin-top: -10px; }

.send-mail {
  height: 85px; }

.alert-text-import {
  font-weight: 100; }

#block-icon-letter span {
  display: inline-block;
  float: left;
  position: relative; }

#block-icon-letter .btn-view-letter:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  background: url(/assets/img/minus.png);
  background-size: 100% 100%;
  top: 10px;
  left: 0;
  text-indent: -99999px; }

#block-icon-letter .icon-attachment {
  margin-right: 10px; }

#block-icon-letter .icon-export-pdf {
  margin-right: 40px; }

.collapsed #block-icon-letter .btn-view-letter:before {
  background: url(/assets/img/plus.png);
  background-size: 100% 100%; }

.m-accordion .m-accordion__item .m-accordion__item-head .m-accordion__item-title.title-letter {
  width: 90%; }

.m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item
> .m-menu__link
.m-menu__link-icon.icon-sidebar-attorney {
  color: white; }

.m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item
> .m-menu__link
.m-menu__link-text.text-sidebar-attorney {
  color: white; }

.m-aside-menu .m-menu__nav > .m-menu__item.m-menu__item--active > .m-menu__link.color-toggle {
  background: white; }

.m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item.color-hover:not(.m-menu__item--parent):not(.m-menu__item--open):not(.m-menu__item--expanded):not(.m-menu__item--active):hover {
  background-color: white; }

.m-grid__item.m-aside-left.m-aside-left--skin-dark.attorney-bg {
  background: #572900; }

.m-aside-menu
.m-menu__nav
> .m-menu__item.m-menu__item--active
> .m-menu__link
.m-menu__link-icon.icon-sidebar-attorney {
  color: #572900 !important; }

.m-aside-menu
.m-menu__nav
> .m-menu__item.m-menu__item--active
> .m-menu__link
.m-menu__link-text.text-sidebar-attorney {
  color: #572900 !important; }

.m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item:not(.m-menu__item--parent):not(.m-menu__item--open):not(.m-menu__item--expanded):not(.m-menu__item--active):hover
> .m-menu__link
.m-menu__link-icon.icon-sidebar-attorney {
  color: #572900; }

.m-aside-menu.m-aside-menu--skin-dark
.m-menu__nav
> .m-menu__item:not(.m-menu__item--parent):not(.m-menu__item--open):not(.m-menu__item--expanded):not(.m-menu__item--active):hover
> .m-menu__link
.m-menu__link-text.text-sidebar-attorney {
  color: #572900; }

.input-group-text {
  padding: 0.65rem 0.3rem; }

.text-two-factor {
  padding-left: 30px;
  padding-right: 30px; }

.border-textCode {
  border: 10px solid #f2f3f8; }

.no-padding {
  padding: 0 !important; }

.m--padding-top-0 {
  padding-top: 0 !important; }

.bg-image {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  background-size: cover; }

button#clear-signature {
  margin: 0 auto;
  display: block; }

button#zoomIn-signature {
  margin: 0 auto;
  display: block; }

textarea#template-editor {
  width: 100%;
  height: 600px;
  background: black;
  color: darkgray; }

#template {
  display: inline-block;
  width: 100%;
  position: relative;
  overflow: hidden; }

#preview-screen {
  height: 600px;
  padding: 10px;
  overflow: auto;
  border: 10px solid #f2f3f8; }

.template-title {
  font-weight: 400;
  color: #3f4047; }

.height-190 {
  height: 190px; }

#case-information .height-190 {
  height: 100px !important; }

.m-card-user.m-card-user--skin-dark .m-card-user__details .m-card-user__name.name-profile {
  color: #aeafc2;
  cursor: context-menu; }

.name-email {
  padding: 6px 0 0 0;
  font-size: 1rem;
  color: #aeafc2;
  cursor: context-menu; }

#ul-blockPortlet .tab-portlet {
  margin-right: 19px; }

#block-step .border-step {
  display: flex;
  border: 1px solid gainsboro; }

#block-step .step-title {
  width: 20%;
  height: 50px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center; }

#block-step .step-content {
  height: 50px;
  padding-left: 10px;
  width: 80%;
  padding-right: 5px;
  display: flex;
  align-items: center; }

.submit-header {
  height: 40px; }

/** END GENERAL CSS **/
/** HOMEPAGE **/
.homepage {
  background: #fff;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #333333;
  text-decoration: none; }

.homepage .container {
  max-width: 1440px !important; }

.homepage #header {
  padding: 12px 0px; }

.homepage #header .logo-phantech {
  margin-left: 13%; }

.homepage #banner {
  height: 80vh;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  background-size: cover; }

.homepage #banner .banner-heading {
  padding-left: 13%; }

.homepage #info .card-block {
  background-color: rgba(255, 255, 255, 0.04);
  margin: 20px 0;
  padding-bottom: 30px;
  text-align: center;
  z-index: 2;
  border: 0 solid rgba(130, 130, 130, 0.2);
  box-shadow: 0 30px 40px -20px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.homepage #info .card-header {
  position: relative;
  padding: 60px 15px;
  color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  background-size: cover; }

.homepage #info .card-body {
  min-height: 333px; }

.homepage #introduce .introduce-img {
  height: 60vh; }

.homepage #introduce .introduce-text {
  padding: 10%; }

.homepage #banner .banner-title {
  font-size: 68px;
  font-weight: bold; }

.homepage #banner .banner-subtitle {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px; }

.homepage #banner .banner-description {
  font-size: 16px;
  font-weight: normal;
  margin-top: 15px;
  width: 70%; }

.homepage #banner #m_login_signin_submit {
  font-size: 16px; }

.homepage #introduce .introduce-title {
  font-size: 32px;
  font-weight: 700; }

.homepage #introduce .introduce-subtitle {
  font-size: 16px;
  color: #999; }

.homepage #introduce .introduce-description {
  font-size: 16px; }

.homepage #info .info-title {
  font-size: 38px;
  font-weight: 700; }

.homepage #info .info-subtitle {
  font-size: 16px;
  color: #999; }

.homepage #info .card-block .block-title {
  font-size: 32px;
  font-weight: bold; }

.homepage #info .card-block .block-heading {
  font-size: 14px; }

.homepage #info .card-block .block-description {
  font-size: 16px; }

.homepage #info .card-block .size-icon {
  font-size: 32px; }

.homepage #quotes .quotes-title {
  font-size: 38px;
  font-weight: 700; }

.homepage #quotes .quotes-subtitle {
  font-size: 16px;
  color: #999; }

.homepage #quotes .quotes-description {
  font-size: 16px;
  padding-left: 20%;
  padding-right: 20%; }

.homepage #quotes #m_login_signin_submit {
  font-size: 16px; }

.homepage #footer .text-footer2 {
  color: #cccccc; }

.homepage #footer a.m-nav__link {
  text-decoration: none; }

/** END - HOMEPAGE **/
/** Start - About **/
#privacy-policy-and-terms {
  font-size: 16px;
  font-family: Arial;
  width: 80%;
  margin-left: 10px; }

#privacy-policy-and-terms .about-title {
  font-size: 18px; }

#privacy-policy-and-terms .about-heading {
  color: red; }

/** END - About **/
.m-page {
  height: 100vh; }

.m-login.m-login--1 .m-login__wrapper {
  padding: 0 !important; }

.btn {
  border-radius: 0; }

/** Start index-pharmacy **/
.text-banner {
  padding-left: 10%; }

.body-pharmacy {
  background: #fff;
  font-family: "Poppins", sans-serif; }

.card-header {
  padding: 0; }

.card-header:first-child {
  position: relative; }

.text-1 {
  color: grey; }

hr.hr-short {
  max-width: 40px;
  border-top: 5px solid #08c1b8; }

.tt-heading.padding-on {
  padding: 6% 3% 5% 3%; }

.text-block {
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), black); }

#footer {
  height: 80px;
  background: #6c757d;
  color: #ccc; }

.row.content-footer {
  padding-top: 28px; }

/** End inex-pharmacy **/
.m-accordion .m-accordion__item .m-accordion__item-head .m-accordion__item-title {
  font-size: 14px !important; }

.pdr {
  margin-left: 53%; }

.pdr1 {
  margin-left: 55%; }

.col-md-9.crel {
  margin-left: 69%; }

.col-md-9.crtl {
  margin-left: 85%; }

.col-md-9.edtl {
  margin-left: 82%; }

li.m-nav__item.opct {
  opacity: 0;
  height: 0; }

p.sign {
  position: absolute;
  bottom: 50%;
  left: 45%;
  font-size: 17px;
  color: silver;
  opacity: 0.3;
  pointer-events: none; }

.col-xl-12.foricon {
  height: 1px;
  opacity: 0; }

.col-md-9.mbt20 {
  margin-bottom: 20px;
  margin-left: 57%; }

p.dateLetter {
  font-size: 12px;
  color: #ccc;
  margin-bottom: 0; }

.col-8.col-md-8.text-capitalize {
  word-wrap: break-word; }

a.m-nav__link.nav-link.m-tabs__link.no-bgr.active i.m-nav__link-icon.fas fa-user-circle {
  color: #5867dd; }

a.m-nav__link.nav-link.m-tabs__link.no-bgr.active i.m-nav__link-icon.fas fa-layer-group {
  color: #5867dd; }

a.m-nav__link.nav-link.m-tabs__link.no-bgr.active i.m-nav__link-icon.la.la-key {
  color: #5867dd; }

a.m-nav__link.nav-link.m-tabs__link.no-bgr.active span.m-nav__link-text {
  color: #5867dd; }

.m-accordion__item:nth-child(even) {
  background: #f8f9fa; }

/* .m-accordion.m-accordion--default .m-accordion__item .m-accordion__item-head{
  background: none !important;
} */
.m-accordion__item-content.colorfff {
  background: #fff; }

.signature {
  padding-top: 5px; }

#zoomOut-signature {
  display: none; }

.btn-signature {
  margin: auto; }

.wrapper-signature .wrapper {
  position: relative;
  width: 100%;
  height: 250px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 5px;
  background: #f8f9fa; }

.wrapper-signature {
  display: block;
  position: relative;
  width: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 5px;
  background: #f8f9fa; }

.signature-pad {
  position: relative;
  left: 0;
  top: 0;
  /* width:100%; */
  height: 200px;
  background-color: #f3f4f8;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  border: 2px solid #ebedf2; }

.m-dropdown__content.wrp {
  margin-left: 12px; }

.col-md-7.col-xs-12.col-7 {
  padding-top: 8px; }

.m-list-timeline__items .m-list-timeline__item {
  padding: 15px 0 !important; }

.m-list-timeline__items .m-list-timeline__item.a {
  padding: 15px 0; }

.m-list-timeline__items.bd:before {
  position: 0 !important; }

.m-list-timeline__items .m-list-timeline__item .m-list-timeline__text.txt {
  font-size: 13px; }

.m-list-timeline__items .m-list-timeline__item .m-list-timeline__time.time {
  width: 130px;
  font-size: 12px;
  color: #ccc; }

.m-portlet__head-title {
  margin-left: -15px; }

.m-portlet__head-title.no-mgl {
  margin-left: -6px; }

h5 {
  padding-left: 9px; }

.mr-auto {
  margin-left: 21px; }

.col-12.m--margin-bottom-30 {
  padding: 0; }

.margin-left-header {
  padding-left: 20px; }

h3.m-portlet__head-text {
  padding-left: 1px; }

span.m-badge.m-badge--default.m-badge--wide.text-capitalize.lop {
  color: #ffff !important;
  background: steelblue; }

span.m-badge.ffff {
  color: #ffff !important; }

span.m-badge.m-badge--default.m-badge--wide.text-capitalize.ffff {
  background: slategrey; }

a.nav-link.m-tabs__link.show.active {
  margin-top: -14px;
  padding: 0;
  padding-bottom: 10px; }

.m-error-1 {
  background-image: url("/assets/img/bg1.jpg");
  height: 100vh; }

#notification-icon {
  background: #7f55dd; }

.user_profile_icon {
  background-image: url("/assets/img/user_profile_bg.jpg");
  background-size: cover; }

#m_aside_left:hover {
  overflow-y: auto;
  overflow-x: hidden; }

#m_aside_left::-webkit-scrollbar {
  width: 6px; }

#m_aside_left::-webkit-scrollbar-track {
  background-color: #2c2e3e; }

#m_aside_left::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; }

.m-wrapper {
  min-height: 85vh; }

.breadcumb-title {
  display: inline !important;
  border-right: 1px solid #d3d3d3; }

.root-loading {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100vh;
  width: 100vh; }

.modal {
  background-color: rgba(0, 0, 0, 0.5); }

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #716aca !important;
  color: white; }

.wraper-hashtag {
  padding: 10px;
  padding-top: 20px; }

.hash-tag {
  background-color: #d3d3d3;
  padding: 0px 5px;
  cursor: pointer;
  margin-right: 10px; }

/* #m_aside_left {
  overflow-y: visible;
} */
.avatar-thumbnail {
  border-radius: 50%; }

.include-prescription {
  display: flex;
  align-items: center; }

.include-prescription span {
  padding: 0 10px; }

#m_topbar_notification_icon label {
  position: absolute;
  left: -30%;
  bottom: 0;
  font-size: 10px;
  background: white; }

.cursor-pointer {
  cursor: pointer; }

.cursor-context {
  cursor: "context-menu" !important; }

tr:hover {
  background-color: #fbf7f7; }

.note-editor {
  z-index: 0 !important; }

.note-editor.note-frame .note-editing-area .note-editable {
  margin-top: 15px; }

.m-list-timeline .m-list-timeline__items .un-read .m-list-timeline__badge:before {
  background-color: #716aca !important; }

.m-intro .m-login__container {
  width: 60% !important; }

.m-tabs-line.nav.nav-tabs .nav-link:hover,
.m-tabs-line.nav.nav-tabs .nav-link.active,
.m-tabs-line a.m-tabs__link:hover,
.m-tabs-line a.m-tabs__link.active {
  border-bottom: 5px solid #34bfa3; }

a[disabled] {
  pointer-events: none !important;
  cursor: default; }

.list-file {
  padding: 10px 0;
  border-bottom: 1px solid #d3d3d3; }

.file-item {
  word-break: break-all; }

.edit-profile {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer; }

.edit-profile:hover {
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  color: #34bfa3; }

td,
td > div {
  word-break: break-word; }

td > span {
  word-break: inherit !important; }

.m-card-profile__details {
  word-wrap: break-word; }

span.m-card-profile__email.m-link {
  word-wrap: break-word; }

.m-loader:before {
  border-top-color: #5867dd; }

.m-card-profile .m-card-profile__details .m-card-profile__name {
  font-size: 1.3rem !important; }

.modal-content {
  margin-top: 85px !important; }

.m--no-padding {
  padding: 0px !important; }

.m-portlet {
  margin-bottom: 1.2rem !important; }

.m-portlet .m-portlet__body {
  padding: 2.2rem 1rem !important; }

.plr {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-datatable.m-datatable--default
> .m-datatable__table
> .m-datatable__body
.m-datatable__row.m-datatable__row--even
> .m-datatable__cell {
  background: #ebedf2; }

.swal2-popup .swal2-icon-close {
  position: absolute;
  right: 20px;
  top: 20px; }

.swal2-popup .swal2-icon-close i {
  font-size: 23px;
  color: #6f727d; }

.swal2-popup .swal2-icon-close a:hover {
  text-decoration: none; }

.track-request-detail-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.bg-accent {
  color: white !important;
  border-color: #00c5dc !important; }

@media only screen and (width: 1024px) {
  .btn-rq {
    margin-bottom: 10px;
    width: 85%;
    border-radius: 20px; }
  .m-datatable {
    overflow: scroll;
    overflow-y: visible; }
  .pdr {
    margin-left: 39%; }
  .pdr1 {
    margin-left: 43%; }
  a.m-nav__link.nav-link.m-tabs__link.active.no-bgr {
    background: none !important; }
  .col-md-9.mbt20 {
    margin-left: 48%; }
  .col-md-9.crel {
    margin-left: 63%; }
  .col-md-9.crtl {
    margin-left: 79%; }
  .col-md-9.edtl {
    margin-left: 75%; }
  #case-information .height-190 {
    height: 80px !important; }
  table {
    min-width: 1000px; }
  #table-scroll-booster table {
    min-width: 0; }
  .m-datatable__pager {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    margin-bottom: 30px; }
  .col-8.col-md-9 {
    word-wrap: break-word;
    padding-right: 50px; }
  .homepage #banner .banner-description {
    width: 80%; }
  .homepage #quotes .quotes-description {
    padding-left: 10%;
    padding-right: 10%; }
  #ul-blockPortlet .tab-portlet {
    margin-right: 30px; }
  .dashboard-chart-wrapper {
    max-height: 250px; } }

@media (max-width: 992px) {
  .m-header--fixed-mobile .m-topbar,
  #m_header_topbar {
    background: transparent;
    margin-top: 0;
    top: -55px;
    z-index: 9;
    width: calc(100% - 20px) !important;
    margin-right: 40px; }
  .m-dropdown__wrapper .m-dropdown__inner {
    margin-right: -60px; }
  .m-dropdown__wrapper .m-dropdown__arrow--adjust {
    left: 265px !important; }
  #m_topbar_notification_icon ~ .m-dropdown__wrapper span {
    /* left: 225.5px !important; */ }
  .m-topbar .m-topbar__nav.m-nav > .m-nav__item > .m-nav__link .m-topbar__userpic img {
    max-height: 30px !important; }
  .dropdown-notification span.m-nav__link-badge.m-badge.m-badge--accent {
    margin-left: 3px !important;
    top: 2px !important; } }

@media only screen and (width: 768px) {
  .dashboard-chart-wrapper {
    max-height: auto; }
  button.btn-rq3 {
    width: 100%;
    border-radius: 20px;
    margin-left: 55px;
    margin-left: 19px; }
  .m-accordion .m-accordion__item .m-accordion__item-head .m-accordion__item-title.title-letter {
    width: 90%; }
  button.btn-rq {
    width: 85%;
    border-radius: 20px; }
  div#info {
    display: contents; }
  .margin-left-header {
    margin-top: 10px; }
  div.general {
    width: 120%; } }

@media (max-width: 1024px) {
  .col-8.col-md-8.col-xl-9.text-capitalize.wrap {
    word-wrap: break-word !important; }
  .m-form .m-form__group {
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 7px !important; } }

@media (min-width: 768px) and (max-width: 1024px) {
  #block-step .step-title {
    width: 30%; } }

@media (max-width: 768px) {
  .letter-body .letter-content-block {
    display: block !important; }
  .letter-utility {
    text-align: left !important; }
  #component-table-database {
    min-width: 0 !important; }
  #ul-blockPortlet .tab-portlet {
    margin-right: 10px; }
  #case-information .height-190 {
    height: 100px !important; }
  .homepage #banner .banner-title {
    font-size: 42px;
    font-weight: bold; }
  .homepage #banner .banner-description {
    width: 100%; }
  .homepage #quotes .quotes-description {
    padding: 0; }
  .col-md-9.mbt20 {
    margin-left: 57%; }
  .text-banner {
    padding-left: 50px; }
  .col-md-9.crel {
    margin-left: 60%; }
  .col-md-9.crtl {
    margin-left: 76%; }
  .col-md-9.edtl {
    margin-left: 72%; }
  .pdr1 {
    margin-left: 54%; }
  .m-intro .m-login__container {
    width: 100% !important; }
  .m-login.m-login--1 .m-login__wrapper {
    padding: 0; }
  .m-login.m-login--1 .m-login__aside {
    padding: 1rem; }
  #component-table-database table thead {
    display: none !important; }
  #component-table-database table tbody tr {
    margin: 35px 0;
    display: block !important; }
  #component-table-database table tbody tr:first-child {
    margin-top: 0;
    display: block !important; }
  #component-table-database table tbody tr td:not([data-type="action"]) {
    padding-left: 40% !important;
    width: 100% !important;
    display: block !important;
    position: relative; }
  #component-table-database table tbody tr td {
    min-width: 100% !important;
    width: 1% !important; }
  #component-table-database table tbody tr td:not([data-type="action"])::before {
    font-size: 14px;
    color: #999999;
    line-height: 1.2;
    font-weight: unset;
    position: absolute;
    width: 40%;
    left: 30px;
    top: 30%; }
  /* table tbody tr td[data-type="action"] [class^="btn"] {
  font-size: 14px;
  width: 100% !important;
  margin: auto;

} */
  table tbody tr td[data-type="action"] [class^="btn"] {
    font-size: 14px; }
  .m-datatable__cell:not([data-type="action"])::before {
    content: attr(data-content); }
  .m-subheader
.m-subheader__breadcrumbs.m-nav
> .m-nav__item.m-nav__item--home
> .m-nav__link
> .m-nav__link-icon.la.la-home {
    padding-right: 0;
    padding-left: 8px; }
  .m-form.m-form--label-align-right.m--margin-top-20.m--margin-bottom-30 {
    margin-bottom: -30px !important; }
  .m-nav.m-nav--hover-bg .m-nav__item:hover > .m-nav__link {
    background-color: none !important; }
  .m-portlet .m-portlet__head.pd {
    padding: 0px; }
  .m-datatable.m-datatable--default > .m-datatable__pager > .m-datatable__pager-info {
    float: right !important;
    display: block;
    margin: 10px 0 0 0; }
  .m-portlet.m-portlet--tabs .m-portlet__head .m-portlet__head-tools {
    margin-top: 0 !important; }
  ul.nav.nav-tabs.m-tabs.m-tabs-line.m-tabs-line--left.m-tabs-line--primary {
    padding: 10px; }
  .col-md-9.col-8 {
    word-wrap: break-word;
    padding-right: 42px; }
  button.btn.btn-accent.m-btn.m-btn--air.m-btn--custom.btn-info.w100 {
    width: 100%;
    font-size: 14px;
    height: 40px;
    border-radius: 20px; }
  a.btn.btn-secondary.m-btn.m-btn--air.m-btn--custom.m--margin-left-10.w100-cancel {
    margin-left: 0 !important;
    width: 100%;
    height: 40px;
    font-size: 14px;
    border-radius: 20px; }
  button.btn.btn-accent.m-btn.m-btn--icon.w100 {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 20px;
    font-size: 14px; }
  .col-8.col-md-8.col-xl-9.text-capitalize.wrap {
    word-wrap: break-word !important; }
  div#m_accordion_1_item_1_head {
    padding-left: 27px; }
  .m-portlet__body.mpbody {
    margin-top: -34px;
    margin-bottom: -34px; }
  .col-9.ml-auto {
    margin-left: 0 !important; }
  .col-10.ml-auto {
    margin-left: 0 !important; }
  .m-portlet__body.mpbodyEpl {
    margin-top: -20px; }
  .m-card-profile.prf {
    margin-top: -39px;
    margin-bottom: -33px; }
  .m-card-profile.prfCompany {
    margin-top: -34px;
    margin-bottom: -48px; }
  label.col-3.col-form-label.text-right.m-r-t {
    margin-left: -35px;
    margin-right: 15px;
    margin-top: -20px; }
  button.btn.btn-accent.m-btn.m-btn--air.m-btn--custom.btn-info.w1001 {
    width: 100%;
    border-radius: 20px;
    height: 40px;
    font-size: 14px;
    margin-bottom: 25px;
    margin-left: -10px; }
  .col-md-3.text-right.m--padding-right-10 {
    text-align: left !important;
    padding-left: 30px;
    margin-top: -23px; }
  a.m-nav__link.nav-link.m-tabs__link.active.no-bgr {
    background: none !important; }
  button.btn.btn-outline-danger.m-btn.m-btn--icon.m-btn--icon-only.btn-rq4 {
    border-radius: 20px;
    margin-left: 10px; }
  .btn-order {
    border-radius: 20px;
    margin-left: 8px; }
  button.btn-rq {
    margin-bottom: 10px; }
  button.btn-rq5 {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    font-size: 14px !important; }
  button.btn-rq6 {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    font-size: 14px !important;
    margin-top: 10px; }
  p.text-footer2 {
    text-align: right; }
  .text-h1 {
    font-size: 20px; }
  .text-h4 {
    font-size: 16px; }
  .text-p {
    font-size: 13px; }
  .btn-reply-letter {
    margin-bottom: 5px;
    margin-top: 5px;
    width: 50%; } }

@media only screen and (max-width: 576px) {
  .icon-dashnoard {
    padding-right: 0 !important;
    margin-left: 10px; }
  .dashboard-chart-wrapper {
    max-height: auto; }
  .m-accordion .m-accordion__item .m-accordion__item-head .m-accordion__item-title.title-letter {
    width: 90%; }
  .m-accordion__item-head.collapsed.display-Block {
    display: block; }
  .m-accordion .m-accordion__item .m-accordion__item-head.display-Block {
    display: block; }
  #block-icon-letter {
    padding-top: 15px; }
  #block-icon-letter .icon-attachment {
    margin-right: 25px;
    text-align: left; }
  #block-icon-letter .icon-export-pdf {
    margin-right: 25px;
    text-align: center; }
  #block-icon-letter .btn-view-detail {
    text-align: right; }
  .send-mail {
    width: 100%;
    border-radius: 20px;
    height: 40px; }
  .btn-order-history {
    margin-top: 10px; }
  .block-activity {
    display: initial; }
  .activity-content {
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 30px; }
  .block-order-list .btn-remove-order {
    margin-left: 0; }
  .case-title {
    margin-left: 0 !important; } }

@media (max-width: 576px) {
  html,
  body {
    font-size: 12px !important; }
  .m-body .m-content {
    padding: 15px 15px !important; }
  .input-group.wd {
    width: 250px;
    text-align: center; }
  .m-portlet .m-portlet__head.a {
    padding: 0 1.2rem; }
  .col-md-9.col-8 {
    word-wrap: break-word;
    padding-right: 20px; }
  .text-two-factor {
    padding-left: 0;
    padding-right: 0; }
  .m-datatable.m-datatable--default > .m-datatable__pager > .m-datatable__pager-info {
    float: none !important;
    display: block;
    margin: 10px 0 0 0; }
  .m-portlet .m-portlet__head {
    display: table;
    padding: 0;
    width: 100%;
    padding: 0px 15px !important;
    height: 5.1rem; }
  .m-datatable__pager.m-datatable--paging-loaded.clearfix {
    text-align: center !important; }
  .m-subheader {
    background: #ffff; }
  h3.m-portlet__head-text {
    font-size: 1.2rem !important; }
  table tbody tr td::before {
    font-size: 12px !important; }
  .col-3.pl {
    margin-left: -14px !important; }
  .col-2.pl {
    padding-left: 20px; }
  .col-2.pr {
    margin-right: 13px; }
  h2.m--margin-10 {
    font-size: 1.3rem !important; }
  table tbody tr td::before {
    left: 10px !important; }
  .m-nav.m-nav--hover-bg .m-nav__item:hover > .m-nav__link,
  .m-nav.m-nav--hover-bg .m-nav__item.m-nav__item--active > .m-nav__link {
    background-color: none !important; }
  .ml-auto,
  .mx-auto {
    margin-left: 0 !important; }
  .col-10.ml-auto {
    margin-left: 0 !important; }
  .col-9.ml-auto {
    margin-left: 0 !important; }
  .col-2.mr {
    margin-right: 15px !important; }
  .m-portlet .m-portlet__body .mrb {
    padding: 15px 15px !important; }
  a.m-nav__link.nav-link.m-tabs__link.active.no-bgr {
    background: none !important; }
  .m-portlet .m-portlet__body.mrt {
    padding: 15px 15px !important; }
  .m-portlet__body {
    padding: 0px !important; }
  .m-form .m-form__group {
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 7px !important; }
  .m-form .m-form__seperator.m-form__seperator--space-2x {
    margin: 20px 0 !important; }
  h3.m-portlet__head-text {
    padding: 15px 15px; }
  .col-md-12.m-row--no-padding {
    padding: 0 !important; }
  div#m_accordion_1_item_1_head {
    padding-left: 0; }
  div#m_accordion_1_item_2_head {
    padding-left: 0; }
  .m--margin-top-20 {
    margin-top: 0 !important; }
  .col-md-3.text-right.m--padding-right-10 {
    text-align: left !important;
    padding-left: 30px;
    margin-top: -23px; }
  ul.nav.m-nav.m-nav--hover-bg.m-portlet-fit--sides {
    margin-bottom: -24px; }
  .m-portlet__body.mpbody {
    margin-top: -34px;
    margin-bottom: -34px; }
  .m-portlet__body.mpbodyEpl {
    margin-top: -20px; }
  button.btn.btn-accent.m-btn.m-btn--air.m-btn--custom.btn-info.w100 {
    width: 100%;
    font-size: 14px;
    height: 40px;
    border-radius: 20px; }
  .row.m-t-b {
    margin-top: -10px;
    margin-bottom: -10px; }
  .m-portlet__body.mpbodyCompany {
    margin-top: -12px; }
  .row.mtbElp {
    margin-bottom: -13px;
    margin-top: -12px; }
  .m-portlet__body.mpdCompany {
    margin-top: -18px; }
  .row.m-t-b-btn {
    margin-bottom: 15px;
    margin-right: 0; }
  .m-portlet__body.mpdProfile {
    margin-top: -9px; }
  ul.mb_nav {
    width: 100% !important;
    display: table; }
  .col-md-6.re1 {
    margin-bottom: 15px; }
  .m--pull-left {
    float: none !important;
    height: 40px; }
  .goback {
    display: none; }
  span.goback1 {
    display: content !important; }
  .m-accordion .m-accordion__item .m-accordion__item-head .m-accordion__item-title {
    font-size: 12px !important; }
  .row.btn-Genaral {
    padding-bottom: 15px;
    margin-top: -11px; }
  button.btn.btn-accent.m-btn.m-btn--icon.w100 {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 20px;
    font-size: 14px; }
  .col-8.col-md-8.col-xl-9.text-capitalize.wrap {
    word-wrap: break-word !important; }
  a.btn.btn-secondary.m-btn.m-btn--air.m-btn--custom.m--margin-left-10.w100-cancel {
    margin-left: 0 !important;
    width: 100%;
    height: 40px;
    font-size: 14px;
    border-radius: 20px; }
  label.col-3.col-form-label.text-right.m-r-t {
    margin-left: -35px;
    margin-right: 15px;
    margin-top: -20px; }
  .track-request-detail-header {
    display: inline; }
  div#m_accordion_1_item_1_head {
    padding-left: 24px; }
  div#m_accordion_1_item_2_head {
    padding-left: 24px; }
  .row.list-file.mbottom {
    margin-bottom: 20px; }
  .m-btn--icon.btn-lg.m-btn--icon-only.m-btn--pill.m-btn--air {
    width: 100% !important;
    margin-top: -6px; }
  button.btn.btn-success.add {
    height: 40px;
    width: 100%;
    border-radius: 20px;
    font-size: 14px;
    margin-right: 17px;
    margin-left: 10px;
    margin-bottom: -5px; }
  a.btn.btn-outline-danger.m-btn.m-btn--custom.m--margin-left-10.btn-cancel {
    width: 100%;
    margin-top: 15px;
    border-radius: 20px;
    height: 40px;
    margin-left: 0 !important;
    font-size: 14px; }
  #button-submit-request .btn-submit {
    width: 100% !important;
    height: 40px;
    line-height: 40px;
    padding: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    border-radius: 20px;
    font-size: 14px; }
  .modal-footer.btn-mf {
    display: table; }
  button.btn-rq {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    font-size: 14px !important;
    margin-top: 10px; }
  button.btn-rq3 {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    font-size: 14px !important;
    margin-top: 10px;
    margin-left: 18px; }
  button.btn-rq1 {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    font-size: 14px !important; }
  button.btn-send {
    width: 100%;
    padding-left: 55px;
    height: 40px;
    font-size: 14px !important; }
  button.btn-send1 {
    width: 100%;
    padding-left: 55px;
    height: 40px;
    font-size: 14px !important;
    margin-top: 10px; }
  button.btn-send2 {
    width: 100%;
    padding-left: 35px;
    height: 40px;
    font-size: 14px !important;
    margin-top: 10px; }
  button.btn-company {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    margin-bottom: -5px; }
  button.btn-edit-el {
    width: 77%;
    margin-left: 42px;
    margin-bottom: 25px;
    height: 40px;
    border-radius: 20px;
    padding-left: 73px;
    font-size: 14px !important; }
  button.btn.btn-accent.m-btn.m-btn--air.m-btn--custom.btn-info.btn-create-el {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    margin-bottom: -20px; }
  button.btn-edit-tl {
    width: 100%;
    margin-left: -10px;
    height: 40px;
    border-radius: 20px;
    padding-left: 75px;
    font-size: 14px !important; }
  button.btn-create-tl {
    width: 100%;
    margin-left: -10px;
    height: 40px;
    border-radius: 20px;
    font-size: 14px !important;
    margin-bottom: 10px; }
  .d-flex.align-items-center {
    margin-left: -10px; }
  .form-group.m-form__group.row {
    padding-left: 0;
    padding-right: 0; }
  .col-xl-12.foricon {
    position: fixed;
    bottom: 0%;
    background: whitesmoke;
    height: 50px;
    border-radius: 17px 17px 0px 0px;
    opacity: 1 !important; }
  a.icon-a {
    width: 25%;
    padding-top: 5px;
    color: gray;
    text-align: center; }
  .i-la {
    font-size: 25px; }
  .text-icon {
    display: inherit; }
  .col-md-3.text-right.m--padding-right-10 {
    margin-left: -30px; }
  .col-md-9.mbt20 {
    margin-bottom: 0 !important;
    margin-left: 0; }
  form.m-form.m-form--fit.m-form--label-align-right.m--margin-top-30.mglt {
    margin-left: 24px;
    margin-right: 22px; }
  button.btn.btn-outline-danger.m-btn.m-btn--icon.m-btn--icon-only.btn-rq4 {
    border-radius: 20px;
    margin-left: 8px; }
  .btn-order {
    border-radius: 20px;
    margin-left: 8px; }
  label.ww {
    word-wrap: break-word !important;
    width: 170px; }
  .clear {
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 5px;
    font-size: 14px; }
  button.btn-rq5 {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    font-size: 14px !important; }
  button.btn-rq6 {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    font-size: 14px !important;
    margin-top: 10px; }
  p.sign {
    left: 35%; }
  .bodylr {
    margin-left: 14px;
    margin-right: 12px; }
  .pdr {
    margin-left: 0; }
  .col-md-9.crel {
    margin-left: 0%; }
  .col-md-9.crtl {
    margin-left: 0; }
  .col-md-9.edtl {
    margin-left: 0; }
  button.btn.btn-accent.m-btn.m-btn--air.m-btn--custom.btn-info.w1005 {
    width: 100%;
    font-size: 14px;
    height: 40px;
    border-radius: 20px; }
  .pdr1 {
    margin-left: 0; }
  .text-banner h1 {
    font-size: 14px; }
  .text-banner h4 {
    font-size: 11px; }
  .text-banner p {
    font-size: 10px;
    width: 300px; }
  .btn-banner {
    margin: 0; }
  p.text-footer2 {
    text-align: center; }
  .row.content-footer {
    padding-top: 20px; }
  .conetnt-row2 {
    height: 250px; }
  div#slide-banner {
    height: 185px; }
  .carousel-inner {
    height: 250px; }
  .img-slide {
    height: 185px; }
  .card.block {
    height: 360px; }
  .text-block {
    height: 53%; }
  .text-footer1 {
    text-align: center; }
  .quotes {
    height: 280px; }
  .d-flex.align-items-center {
    margin-left: 0; }
  .text-banner {
    padding-left: 30px; }
  .hr-short {
    margin-top: 20px !important; }
  .homepage #info .card-body {
    min-height: 0; }
  #about {
    width: 100%;
    padding-right: 10px; }
  .homepage #banner .banner-heading {
    margin-left: 0; }
  .homepage #header .logo-phantech {
    margin-left: 0; }
  #case-information .height-190 {
    height: 80px !important; }
  .height-190 {
    height: none; }
  #ul-blockPortlet .tab-portlet {
    margin-right: 10px; }
  #divsign img {
    width: 100%; }
  table tbody tr td[data-type="action"] [class^="btn"] {
    font-size: 13px;
    width: 95% !important; } }

.btn-update-client {
  background: #fff;
  color: red;
  width: 100%;
  border: 1px solid red;
  border-radius: 5px; }

.label-action-now {
  display: block;
  float: left;
  min-height: 65px;
  width: 110px; }

.label-action-now span {
  display: inline-block;
  background: #fff;
  border: 1px solid red;
  border-radius: 2px;
  color: red;
  padding: 0 5px;
  transform: translateX(0%) translateY(18px) rotate(-25deg); }

.m-aside-left--fixed .m-body {
  transition: none !important; }

.m-messenger__message-pic {
  position: relative; }

.m-messenger__message-pic.online:before {
  background: #20c997; }

.m-messenger__message-pic:before {
  content: " ";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 35px;
  left: 30px;
  background: #6c757d;
  border-radius: 50px; }

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%; }

@media only screen and (max-width: 768px) {
  #table-import-patient table {
    display: block; }
  #table-import-patient thead {
    display: block; }
  #table-import-patient tbody {
    display: block; }
  #table-import-patient th {
    display: block; }
  #table-import-patient td {
    display: block; }
  #table-import-patient tr {
    display: block; }
  #table-import-patient thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  #table-import-patient tr {
    margin: 0 0 1rem 0; }
  #table-import-patient td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%; }
  #table-import-patient td:before {
    content: attr(data-title);
    position: absolute;
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    line-height: 4.5; }
  .technical-icon {
    width: 40px; } }

input[type="number"] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

td[data-highlight="true"] {
  background-color: #f29900; }

.count-report-LoP {
  width: 95% !important; }

#dates-information input[type="date"]::-webkit-clear-button {
  visibility: hidden; }

#dates-information input[type="date"]:hover::-webkit-clear-button {
  visibility: visible;
  opacity: 1;
  font-size: 18px;
  padding-right: 5px;
  cursor: pointer; }

#dates-information input[type="date"] {
  position: relative; }

#dates-information input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
  font-size: 15px;
  cursor: pointer; }

#dates-information input[type="date"]:before {
  font-family: "Font Awesome 5 Free";
  content: "";
  /* This is the calendar icon in FontAwesome */
  width: 25px;
  position: absolute;
  top: 11px;
  right: 0;
  color: #999;
  font-size: 15px;
  cursor: pointer; }

input[type="date"]::-webkit-clear-button {
  opacity: 0; }

/** modal-compose-letter **/
.modal-compose-letter .m-portlet {
  margin: 0px !important; }

.modal-compose-letter .modal-content {
  border: 1px solid #343a40;
  box-shadow: 0px 0px 10px #fff; }

/** end modal-compose-letter **/
.submit-block {
  border-width: 1px;
  border-style: solid; }
  .submit-block .m-portlet__head {
    display: flex; }

.ck-editor .ck-editor__editable {
  min-height: 200px; }

.ck-editor .ck-toolbar__items .ck-icon {
  z-index: 0 !important; }

/** start modal-case-readiness **/
.modal-case-readiness .item-readiness:hover {
  background-color: #eee; }
  .modal-case-readiness .item-readiness:hover .checkbox {
    background-color: white; }
  .modal-case-readiness .item-readiness:hover .option-item {
    visibility: initial; }

.modal-case-readiness .item-readiness .container-check-list {
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .modal-case-readiness .item-readiness .container-check-list input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0; }
  .modal-case-readiness .item-readiness .container-check-list .checkbox {
    background-color: #eee;
    position: absolute;
    left: 10px;
    height: 20px;
    width: 20px;
    border: 1px solid #ccc;
    cursor: pointer; }
    .modal-case-readiness .item-readiness .container-check-list .checkbox.checked:after {
      content: "";
      position: absolute;
      top: 3px;
      left: 7px;
      width: 5px;
      height: 10px;
      border: solid #9DA9C1;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }

.modal-case-readiness .item-readiness .textarea-readiness {
  background: rgba(9, 30, 66, 0.04);
  border-color: rgba(9, 30, 66, 0.13); }

.modal-case-readiness .item-readiness .btn-action-readiness {
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 3px; }

.modal-case-readiness .item-readiness .btn-action-readiness-setting {
  margin-right: 10px;
  border-radius: 3px; }

.modal-case-readiness .item-readiness .icon-cancel-edit {
  font-size: 24px;
  color: #172b4d;
  cursor: pointer; }

.modal-case-readiness .item-readiness .option-item {
  visibility: hidden; }

/** end modal-case-readiness **/
.border-center-space {
  border-bottom: 1px dashed #ebedf2; }

.border-radius-3 {
  border-radius: 3px; }

.icon-cancel-edit {
  font-size: 24px;
  color: #172b4d;
  cursor: pointer; }

.opacity-0 {
  opacity: 0;
  cursor: context-menu !important; }

.setting-readiness-tab {
  border: 1px solid #ddd; }
  .setting-readiness-tab .nav-item {
    margin-left: 0 !important;
    margin-bottom: 0 !important; }
  .setting-readiness-tab .nav-readiness a:hover {
    background: #fff;
    border-color: #fff #ddd #fff #fff !important; }
  .setting-readiness-tab .nav-readiness a:last-child {
    border-right: none; }
  .setting-readiness-tab .nav-readiness a:first-child {
    border-left: none; }
  .setting-readiness-tab .nav-readiness .active {
    background: #fff !important;
    font-weight: bold;
    border-color: #fff #ddd #fff #fff !important; }
  .setting-readiness-tab .nav-readiness .nav-border-item {
    color: #000;
    background: #eee;
    margin-left: 0;
    border-right: 1px solid #ddd !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.action-readiness {
  margin-bottom: 20px; }
  .action-readiness span {
    line-height: 30px; }
  .action-readiness .btn-action button {
    width: 60px;
    height: 30px !important;
    line-height: 30px;
    padding: 0; }
  .action-readiness .btn-action .yes-active {
    background-color: #f0f0f0;
    border-radius: 5px 0px 0px 5px; }
  .action-readiness .btn-action .no-active {
    background-color: #f0f0f0;
    border-radius: 0px 5px 5px 0px; }
  .action-readiness .btn-action .btn-yes-action {
    background-color: #0ABB87;
    color: #FFFFFF;
    border-radius: 5px 0px 0px 5px; }
  .action-readiness .btn-action .btn-no-action {
    background-color: #FD397A;
    color: #FFFFFF;
    border-radius: 0px 5px 5px 0px; }

.tabs {
  max-width: 640px;
  margin: 0 auto;
  padding: 0 20px; }

#tab-button {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none; }

#tab-button li {
  display: table-cell;
  width: 20%; }

#tab-button li a {
  display: block;
  padding: .5em;
  background: #eee;
  border: 1px solid #ddd;
  text-align: center;
  color: #000;
  text-decoration: none; }

#tab-button li:not(:first-child) a {
  border-left: none; }

#tab-button li a:hover,
#tab-button .is-active a {
  border-bottom-color: transparent;
  background: #fff; }

.tab-contents {
  padding: .5em 2em 1em;
  border: 1px solid #ddd; }

.tab-button-outer {
  display: none; }

.tab-contents {
  margin-top: 20px; }

@media screen and (min-width: 768px) {
  .tab-button-outer {
    position: relative;
    z-index: 2;
    display: block; }
  .tab-select-outer {
    display: none; }
  .tab-contents {
    position: relative;
    top: -1px;
    margin-top: 0; } }

.react-autosuggest__container {
  position: relative; }
  .react-autosuggest__container .react-autosuggest__input {
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px; }
  .react-autosuggest__container .react-autosuggest__input--focused {
    outline: none; }
  .react-autosuggest__container .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .react-autosuggest__container .react-autosuggest__suggestions-container {
    display: none; }
  .react-autosuggest__container .react-autosuggest__suggestions-container--open {
    display: block;
    top: 51px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2; }
  .react-autosuggest__container .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  .react-autosuggest__container .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px; }
  .react-autosuggest__container .react-autosuggest__suggestion--highlighted {
    background-color: #ddd; }

.icon-breadcrumb-request {
  margin-right: 10px;
  text-align: center;
  cursor: pointer !important;
  display: inline-grid;
  text-align: center;
  color: #888EB2; }
  .icon-breadcrumb-request i {
    font-size: 20px;
    position: relative; }
  .icon-breadcrumb-request label {
    font-size: 10px; }
  .icon-breadcrumb-request .active-true:after {
    font-family: "Font Awesome 5 Free" !important;
    color: green;
    content: "";
    font-size: 14px !important;
    position: absolute;
    top: 0px; }
  .icon-breadcrumb-request .active-false:after {
    font-family: "Font Awesome 5 Free" !important;
    color: red;
    content: "";
    font-size: 14px !important;
    position: absolute;
    top: 0px; }

.affidavit-automation-page {
  background: white;
  padding-bottom: 20%; }
  .affidavit-automation-page .block-title-page {
    background: #00ABB5;
    height: 300px;
    text-align: center;
    justify-content: center;
    padding-top: 35px; }
    .affidavit-automation-page .block-title-page .title-content p {
      font-size: 40px;
      color: white;
      font-weight: 600; }
    .affidavit-automation-page .block-title-page .title-content span {
      font-size: 18px;
      color: white;
      font-weight: 500; }
  .affidavit-automation-page .card-block {
    justify-content: center; }
    .affidavit-automation-page .card-block .card-block-step {
      text-align: center;
      box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
      background: #ffffff;
      min-height: 400px;
      margin-top: -100px; }
      .affidavit-automation-page .card-block .card-block-step .header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
        .affidavit-automation-page .card-block .card-block-step .header .header-content {
          padding: 20px 0px;
          font-size: 16px; }
          .affidavit-automation-page .card-block .card-block-step .header .header-content .step {
            display: inline-block;
            font-size: 30px;
            color: white;
            text-align: center;
            border-radius: 50px !important; }
    .affidavit-automation-page .card-block .block-description {
      padding: 20px;
      font-weight: 400; }

.utility {
  position: fixed;
  z-index: 999;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease; }
  .utility .button-utility {
    background: darkblue !important;
    height: 35px; }
  .utility .menu-utility {
    width: 180px;
    margin-left: 60px;
    margin-top: -36px;
    cursor: pointer; }
    .utility .menu-utility .dropdown-item.active {
      color: #fff !important; }
    .utility .menu-utility .dropdown-item label {
      margin-bottom: 0px; }
    .utility .menu-utility .active-true:after {
      font-family: "Font Awesome 5 Free" !important;
      color: green;
      content: "";
      font-size: 10px !important;
      position: absolute;
      top: -5px; }
    .utility .menu-utility .active-false:after {
      font-family: "Font Awesome 5 Free" !important;
      color: red;
      content: "";
      font-size: 10px !important;
      position: absolute;
      top: -5px; }

.btn.dropdown-toggle:after {
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900;
  content: "" !important; }

.icon-angle-down:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: ""; }

.search-form span {
  height: 100%; }

.search-form i {
  font-size: 17px; }

.track-request-detail-header .padding-request-detail-header {
  margin-right: 20px; }
  .track-request-detail-header .padding-request-detail-header .css-1pcexqc-container {
    width: 250px; }

.customer-provider .nav-tabs .nav-link {
  border-left: none;
  border-right: none;
  border-top: none;
  font-size: 14px  !important;
  padding-left: 0 !important;
  padding: 13px 3px !important; }

.btn-group .btn-primary:after {
  display: none; }

.btn-group .clean {
  border-color: transparent !important;
  background: 0 0 !important;
  color: #93a2dd !important; }
  .btn-group .clean:hover {
    background-color: #edf0fc !important; }

.btn-group .btn-clean {
  border-color: transparent;
  background: 0 0;
  color: #93a2dd; }
  .btn-group .btn-clean:hover {
    background-color: #edf0fc !important; }

#page-dashboard .nav-tabs {
  border-bottom: 0; }
  #page-dashboard .nav-tabs .nav-link {
    border: 0; }
    #page-dashboard .nav-tabs .nav-link:hover {
      color: #5867dd !important; }
  #page-dashboard .nav-tabs .nav-link.active {
    color: #5867dd !important; }

.messenger-caret-right::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "";
  font-size: 30px;
  color: #716aca;
  float: right; }

.messenger-caret-left::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "";
  font-size: 30px;
  color: #f4f5f8;
  float: left; }

.dropdown-notification {
  height: 100%; }
  .dropdown-notification .btn-primary:after {
    display: none; }
  .dropdown-notification .clean {
    border-color: transparent !important;
    background: 0 0 !important;
    height: 100%; }
  .dropdown-notification span.m-nav__link-badge.m-badge.m-badge--accent {
    left: 50%;
    margin-left: 2px;
    position: absolute;
    top: 13px; }
  .dropdown-notification span.m-nav__link-icon {
    text-align: center;
    line-height: 0;
    vertical-align: middle;
    padding: 0; }
  .dropdown-notification i.far.fa-bell {
    color: #98a4c4;
    font-size: 1.75rem; }
  .dropdown-notification label.m--hidden-tablet-and-mobile {
    font-weight: 500; }
  .dropdown-notification a.dropdown-item {
    white-space: initial; }
  .dropdown-notification .m-dropdown__inner {
    background-color: #ffffff;
    box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
    font-weight: 500; }
    .dropdown-notification .m-dropdown__inner div#notification-icon {
      padding: 20px 20px;
      border-radius: 4px 4px 0 0; }
    .dropdown-notification .m-dropdown__inner span.m-dropdown__header-title {
      display: block;
      padding: 0 0 5px 0;
      font-size: 1.5rem;
      font-weight: 400; }
    .dropdown-notification .m-dropdown__inner span.m-dropdown__header-subtitle {
      display: block;
      padding: 0px;
      font-size: 1rem; }
    .dropdown-notification .m-dropdown__inner .m-dropdown__body {
      padding: 20px; }
    .dropdown-notification .m-dropdown__inner .text-bottom-notify {
      position: fixed;
      bottom: 0%;
      margin-left: -20px;
      width: 96%;
      background: gainsboro;
      height: 30px;
      border-radius: 17px 17px 0px 0px; }
      .dropdown-notification .m-dropdown__inner .text-bottom-notify .content {
        font-size: 13px;
        padding-top: 7px;
        font-weight: 500; }

.title-utility {
  cursor: pointer; }
  .title-utility:hover {
    color: #5867dd !important; }

.dropdown-profile-user {
  height: 100%; }
  .dropdown-profile-user .btn-primary:after {
    display: none; }
  .dropdown-profile-user .clean {
    border-color: transparent !important;
    background: 0 0 !important;
    height: 100%; }
  .dropdown-profile-user .m-dropdown__inner:focus {
    background-color: 0 !important; }
  .dropdown-profile-user .dropdown-menu > .dropdown-item:hover {
    background: none !important; }
  .dropdown-profile-user .dropdown-menu > .dropdown-item:focus {
    background: none !important; }
  .dropdown-profile-user .dropdown-menu > .dropdown-item:active {
    background: none !important; }

.affidavit-type-request .border-affidavit {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 8px 10px 0 10px; }

.affidavit-type-request p {
  margin-top: 4px;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px; }

.affidavit-type-request .checkbox {
  position: absolute;
  top: 11px;
  left: 10px;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  cursor: pointer; }
  .affidavit-type-request .checkbox.checked:after {
    content: "";
    position: absolute;
    top: -3px;
    left: 7px;
    width: 8px;
    height: 19px;
    border: solid red;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }

.modal-attachment-automation .note-prepare-attachment-left {
  margin-top: 30px;
  padding: 10px;
  background: #E0EBF9;
  color: #3DA4B6;
  height: 95px; }

.modal-attachment-automation .note-prepare-attachment-right {
  margin-top: 30px;
  padding: 10px;
  background: #ABE7ED;
  color: #3296E2;
  height: 95px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  left: 15px; }

.list-attachment-by-request ol {
  counter-reset: myCounter;
  margin-left: 0;
  color: #646464; }

.list-attachment-by-request li {
  position: relative;
  padding-left: 3em;
  margin: 0.45em 0;
  list-style: none;
  line-height: 1.8em;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.list-attachment-by-request li:hover {
  color: black; }

.list-attachment-by-request li:before {
  content: counter(myCounter);
  counter-increment: myCounter;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  padding: 0px;
  color: #fff;
  background: #2980b9;
  font-weight: bold;
  text-align: center;
  border-radius: .9em;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.list-attachment-by-request li:hover:before {
  background-color: #2ecc71; }

.list-attachment-by-request li li:before {
  background-color: #3498db; }

.list-attachment-by-request li:after {
  position: absolute;
  top: 2.1em;
  left: 0.9em;
  width: 2px;
  height: calc(100% - 2em);
  content: '';
  background-color: #cbcbcb;
  z-index: 0; }

.list-attachment-by-request li:hover:after {
  background-color: #2ecc71; }

/** Google reCaptcha **/
.grecaptcha-badge {
  visibility: hidden; }

body.modal-open {
  overflow: hidden !important; }

.screen-notice {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
  position: fixed; }
